import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';

function App() {
  const [tab, setTab] = useState("home");
  let data;

  switch(tab) {
    case "home":
      data = <Home />;
      break;
    case "about":
      data = <About />;
      break;
    case "contact":
      data = <Contact />;
      break;
    default:
      data = <Home />;
      break;
  }

  return (
    <div>
      <header>
          <div className="innerHeader">
              <div className="leftHeader">
                <div className="logo">
                    <img src="../../summit.png" />
                    <h2>Summit <span>Enterprises, LLC</span></h2>
                </div>
              </div>
              <section class="top-nav">
              <input id="menu-toggle" type="checkbox" />
              <label class='menu-button-container' for="menu-toggle">
                  <div class='menu-button'></div>
              </label>
              <ul class="menu">
                  <li onClick={() => setTab("home")}>Home</li>
                  <li onClick={() => setTab("home")}>About</li>
                  <li onClick={() => setTab("home")}>Contact</li>
              </ul>
            </section>
          </div>
      </header>
      {data}
    </div>
  );
}

export default App;
